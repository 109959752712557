*, *:before, *:after {
	box-sizing:border-box;
}

html, body {
	display:block;
	height:100vh;
	background:rgba(0, 0, 0, 1);
}

#root {
	display:flex;
	align-items:center;
	justify-content:center;
	height:100vh;
	background:linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(255, 91, 54, 0.1) 100%);
}

img {
	display:block;
	margin:0 auto;
	max-width:90%;
}